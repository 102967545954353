@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Gloock&family=Nunito:wght@700&display=swap');
:root{
    --box-shadow:0.5rem 1.5rem rgba(0,0,0,.1);
}
*{
    font-family:'Nunito', sans-serif;
    margin: 0; padding:0;
    box-sizing:border-box;
    text-decoration: none;
    outline: none; border: none;
    text-transform: capitalize;
    transition: all .2s linear;
}

html{
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-padding-top: 5.5rem;
    scroll-behavior: smooth;
}

section{
    padding: 2rem 9%;
}

section:nth-child(even){
    background: #eee;
}

.sub-heading{
    text-align: center;
    color: rgb(15, 102, 114);
    font-size: 2rem;
    padding-top: 1rem;
}

.heading{
    text-align: center;
    color: #274400;
        font-size: 3rem;
    padding-top: 2rem;
    text-transform: uppercase;
}


.btn{
    margin-top: 1rem;
    display: inline-block;
    font-size: 1.7rem;
    color:#fff;
    background: #274400;

    border-radius: .5rem;
    cursor: pointer;
    padding: .8rem 2rem;
    
}

/* Define a class for round buttons */
.rounded-button {
    margin-top: 1rem;
    font-size: 1.7rem;
    font-family:'Times New Roman', Times, serif;

    display: inline-block;
    padding: 10px 10px; /* Adjust the padding as needed */
    border-radius: .5rem; /* Makes the button round */
    background-color: rgb(34, 148, 34); /* Change the background color as needed */
    color: #fff; /* Change the text color as needed */
    text-decoration: none;
    transition: background-color 0.3s ease; /* Add a transition for hover effect */
  }
  
  /* Define hover effect */
  .rounded-button:hover {
    background-color: #274400; /* Change the background color on hover */
  }
  


.btn2{
    margin-top:0;
    display: inline-block;
    font-size: 1.7rem;
    color:#fff;
    background: #25d366;

    border-radius: .5rem;
    cursor: pointer;
    padding: .8rem 2rem;
    
}
.btn:hover{
    background: #274400;
    letter-spacing: .1rem;
}

.btn:hover{
    background: rgb(39, 158, 39); 
    letter-spacing: .1rem;
}

header{
    position: fixed;
    top: 0; left: 0; right: 0;
    background: #274400;
    padding:0.5rem 7% 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    z-index: 1000;
    box-shadow: 0.2rem 1rem rgba(5, 87, 2, 0.1);
}

header .logo{
    
    color: rgb(15, 102, 114);
    font-size: 1.5rem;
    font-weight: bolder;
}


header .navbar a{
    font-size: 1.7rem;
    border-radius: .5rem;
    padding:.4rem 3rem;
    color: rgb(238, 223, 15);
    
}

header .navbar a.active,


header .navbar a:hover{
    color: #fff;
    background: rgb(179, 167, 2);
}

header .icons i,
header .icons a{
    cursor: pointer;
    margin-left:.5rem;
    height: 4.5rem;
    line-height: 4.5rem;
    width: 4.5rem;
    text-align: center;
    font-size: 1.7rem;
    color: rgb(15, 102, 114);
    border-radius: 50%;
    background: #fff;
}

header .icons i:hover,
header .icons a:hover{
    color: #fff;
    background: rgb(39, 158, 39);     
    transform: rotate(360deg);  
}

header .icons #menu-bars{
    display: none;
}








@media(max-width:768px){
    .home{
        padding-bottom: 0;
    
    }
    .home .herotext{
        padding-top: 1rem;
    }
    
    .home .home-slider .slide .content {
        flex: 1 1 100%;
        padding-left: 0%;
        padding-right: 6rem;
        text-align: center;
        padding-top:0;
    }

    .home .home-slider .slide .content .span-text {
        font-size: 1.8rem; /* Reduce font size for mobile */
        padding: 1rem 0;
    }
    

    .home .home-slider .slide .content .btn-adjust {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        margin-right: 9rem;
        margin-left: 0%;
        padding-bottom: 0;

        
    }
            /* .home .home-slider .slide .content .hero-image{
                padding-top: 0rem;
            } */

  

   
    /* .home .home-slider .slide .content .btn-adjust {
        justify-content: center;
        text-align: center;
    } */

    /* Optionally, you can make the image responsive */
    .home .home-slider .slide .image{
        margin-top: 0%;
        padding-top: 0;

        
    }
    
    .home .home-slider .slide .image .herotext{
        padding-top: 0%;
    }
    .home .home-slider .slide .image img {
        max-width: 100%;
        height: auto;
         padding-top: 0;
         margin-top: -12rem;
    }
    .home .home-slider .slide .time-image{
        max-width: 100%;
        padding-left: 25rem;
        padding-top: 0;
       
        
    }
    .home .home-slider .slide .content p{
        margin-right: 3rem;
    }
    /* .home .home-slider .slide .content .hero-fix{
        margin-left: 1rem;
    } */
    .home .herotext{
        padding-bottom: 2rem;
    }
 


    .text-button-hero {
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Align items to the start (left) */
      }
    
      .hero-p {
        order: 1; /* Change the order to stack <p> above the button */
        margin-bottom: 10px; /* Add spacing between <p> and the button */
      }
    
      .btn-adjust {
        order: 2; /* Change the order to display the button below <p> */
      }
    
      
    header .icons #menu-bars{
    display: inline-block;
    }

    header .navbar {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: #fff;
        border-top: .1rem solid rgba(0, 0, 0, .2);
        padding: 1rem;
        max-height: 0; /* Add this line */
        overflow: hidden; /* Add this line to hide the content */
        transition: max-height 0.3s ease-in-out; /* Add this line for smooth transition */
    }
    

    header .navbar.active{
        clip-path: polygon(0 0,100% 0,100% 100%,0% 100%);
    }

    header .navbar a{
        display:block;
        padding: 1.5rem;
        margin: 1rem;
        font-size: 2rem;
        background: #eee;
    }

    iframe {
        width: 100%; /* Set the width to 100% for responsiveness */
        height: 300px; /* Adjust the height as needed for your design */
      }
    
    .container {
        flex-direction: column;
        align-items: center;
      }
    
      .map{
        max-width: 100% ;
      }
    
      .map, .contact-details {
        width: 100%;
        
        margin-right: 0;
      }

      .responsive-image-section .responsive-image {
        max-width: 75%;
      }
}


@media(max-width:991px){
    html{
        font-size: 55%;
    }
    header{
        padding: 1rem 2rem;
    }
    section{
        padding: 2rem;
    }

  
}

@media(max-width:991px){
    .home .home-slider .slide .image img{
       padding-top: 4rem;
        
       
    }
}
@media(max-width:991px){
    html{
        font-size: 50%;
    }

    
    
}

.home .home-slider .slide{
    display: flex;
    flex-wrap:wrap;
    gap: 2rem;
    padding-top:0;
}

.home .home-slider .slide .content{
    flex:1 1 45rem;
    padding-top: 15rem;
    padding-left: 0;
}
.home .home-slider .slide .content .hero-fix   {
    flex: 1 1 45rem;
    padding-top: 0;
    padding-left: 5rem;
    justify-content: center;
    align-items: center;
}


.home .home-slider .slide .content .p{
    
        text-align: center  ;
        
}
.home .home-slider .slide .content p{
    margin-top: 1rem;
    margin-top: 2rem;
    margin-left: 11rem;

font-style: italic;
font-size: 1.7rem;
}


.home .home-slider .slide .image{
    flex:1 1 30rem;
    padding-top: 10rem;
}
.home .home-slider .slide .content .hero-image{
    flex:1 1 30rem;
    padding-left: 0rem;
    

}

.home .herotext{
    padding-top: 10rem;
}

.home .herotext h1{
    font-size: 10rem;
    color: #274400;
    /* font-family: 'Archivo Black', sans-serif; */
     font-family: 'Gloock', serif;
    /* font-family: 'Nunito', sans-serif;  */
/* font-family: 'Nunito', sans-serif; */
text-align: center;
justify-content: center;
    }

.home .home-slider .slide .image img{
    max-width: 100%;
    height: auto;
   padding-left: 5rem;
   
    
   
}

.home-slider .slide  .time p{
    color: #25d366;
    margin-top: 35rem;
    justify-content: center;

}

.home .home-slider .slide .content span{
    color: rgb(50, 136, 50);
    font-size: 2.5rem;
    padding: .5rem 1.2rem;
    padding-left: 5rem;
}

/* .home .home-slider .slide .content h3{
    color: rgb(15, 102, 114);
    font-size: 6rem;
} */

.home .hero-image .slide .content .btn-adjust .btn {
    margin-left: 20rem; /* Add this line to move the button 3rem to the right */
  
}



.services .box-container{
    padding-top: 5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(30rem, 1fr));
    gap: 6rem;

}
.services .box-container .box{
    opacity: 90%;   
    padding: 2.5rem;
    height: 35rem;
    background: #fff;
    border-radius: .5rem;
    border: 1px solid black;
    box-shadow: var(--box-shadow);
    position: relative;
    overflow: hidden;
    text-align: center;
}

.services .box-container .box img{
    height: 18rem;
    margin: 1rem 0;
}

.services .box-container .box h3{
    font-size: 2.1rem;
    font-family:Arial, Helvetica, sans-serif;
    color: #274400;
}

.services .box-container .box p{
    padding-top: .8rem;
    font-size: 1.2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.services .box-container .box:hover{
    opacity: 100%;
    border: 2.5px solid black;
}



/* .how .row{
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;
} */

/* .hpw .row .image{
    flex: 1 1 45rem;
} */

.responsive-image-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    text-align: center; /* Center-align text content */
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  
  .sub-heading {
    font-size: 16px;
    margin-bottom: 10px;
    color: rgb(50, 136, 50); /* Adjust the color as needed */
  }
  
  .heading {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #274400; /* Adjust the color as needed */
  }
  
  .responsive-image {
    max-width: 40%;
    margin-right: 2rem;
    /* height: auto; */
    
    margin-top: 1rem;
  }
  





.about .row{
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;
}

.about .row .image{
    flex: 1 1 45rem;
}
.about .row .image img{
    width: 100%;
}
.about .row .content{
    flex: 1 1 45rem;
}
.about .row .content h3{
    color: #274400;
    font-size: 4rem;
    padding: 1rem 0;
    padding-top: 2.5rem;
}
.about .row .content p{
    color:rgb(43, 46, 46);
    font-size: 1.5rem;
    padding: .5rem 0;
    line-height: 2;
    text-transform:none;
}

.about .row .content .icons-container{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 1rem 0;
    margin-top: .5rem;
}

.about .row .content .icons-container .icons{
    background: #eee;
    border-radius: .5rem;
    border: .1rem solid rgba(0, 0, 0, .2);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex:1 1 17rem;
    padding: 1.5rem 1rem;
}

.about .row .content .icons-container .icons i{
    font-size: 2.5rem;
    color: rgb(34, 148, 34);
}

.about .row .content .icons-container .icons span{
    font-size: 1.5rem;
    color: black;
}

.review {
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
    padding-bottom: 5rem;
}
.review .slide{
    width: 30rem;
    height: 20rem;
    padding: 2rem;
    box-shadow: var(--box-shadow);
    border: .1rem solid rgba(0, 0, 0, .2);
    border-radius:.5rem;
    position: relative;
}

.review .slide .fa-quote-right{
    position:absolute;
    top:2rem;right:2rem;
    font-size: 2rem;
}

.review .slide .user{
    display: flex;
    gap:1rem;
    align-items: center;
    padding-bottom: 1.5rem;
}

.review .slide p{
    text-transform: none;
}


.review .slide .user img{
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    object-fit: cover;
}

.container {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    color: rgb(192, 192, 192);
  
    /* Add any additional styles for the container */
  }
  
  .map {
    width: 100%;
    max-width: 600px;
    height: auto;
    margin-bottom: 20px;
  
    /* Add any additional styles for the map */
  }
  
  .contact-details {
    width: 100%;
    padding-top: 5rem;
    padding-left: 10rem;
    background-color: #0e132b;
    border-radius: 5px;
    height: 400px;
    margin-bottom: 20px;
    /* Add any additional styles for the contact details */
  }

  .contact-details .title {
    font-size: 30px;
    padding-bottom: 2rem;
  }

  .contact-details .location h2 {
    font-size: 20px;
    padding-top: 2rem;
  }

  .contact-details .location p {
    font-size: 15px;
    padding-top: 1.8rem;
  }

  .contact-details .contact-info h2{
    font-size: 18px;
    padding-top: 1.8rem;
  }
  .contact-details .contact-info p{
    font-size: 13px;
    padding-top: 1.5rem;
  }
  .contact-details .contact-info .copy p{
    font-size: 10px;
    padding-top: 1.5rem;
  }
  @media (min-width: 768px) {
    .container {
      flex-direction: row;
      align-items: flex-start;
    }
  
    .map {
      flex: 1;
      margin-right: 10px;
    }

   
  }